<template>
	<div class="hx-order-ten">
		<div class="left" align="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" class="infoimg" width="90px" height="110px"
				style="vertical-align: middle;">
			<div>
				<h3 style="margin: 0px;margin-bottom: 3px;">提交订单</h3>
				<div align="left" style="margin-bottom: 0.625rem;margin-top: 20px;">请接受以下条款并提交订单</div>
				<div align="left" style="margin-bottom: 0.625rem;">请填写收货地址</div>
			</div>
		</div>
		<!-- 右侧 -->
		<div class="right">

			<div>

				<div class="con">

					<div class="right" align="left">
						<h4>选择收货地址
						<el-button style="float: right;margin-right: 85px;" size="small" class="nextStep" type="primary" @click="goSettings">新增收货地址</el-button>
						</h4>
						<el-select v-if="addressList.length>0" v-model="value" placeholder="请选择">
						    <el-option
						      v-for="item in addressList"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						  <el-select v-if="addressList==[]"  placeholder="请选择">
						      <!-- <el-option
						        v-for="item in addressList"
						        :key="item.value"
						        :label="item.label"
						        :value="item.value">
						      </el-option> -->
							  <el-option  label="暂无地址"></el-option>
						    </el-select>
						<h4 align="left" style="margin-bottom: 0.625rem;margin-top: 30px;">请勾选下列条款并提交订单</h4>
						
						<input class="abc1" type="checkbox" @click="checkedOne" />我确认所有给定数据的正确性。<br/>
						<input class="abc2" type="checkbox" @click="checkedOne"/>我接受所有<a  href="./zhengce.html" target="_blank">隐私政策</a><br/>
						<input class="abc3" type="checkbox"@click="checkedOne" />我接受所有<a  href="./tiaokuan.html" target="_blank">使用条款</a><br/>
						<input  class="abc0" type="checkbox" @click="allChecked">全选
						<!-- <el-checkbox-group v-model="checkList">
							<el-checkbox  label="我确认所有给定数据的正确性。"></el-checkbox><br>
							<el-checkbox @click="zhengCe" label="我接受所有隐私政策"></el-checkbox><br>
							<el-checkbox label="我接受所有使用条款"></el-checkbox>
						</el-checkbox-group> -->
						<!-- <h4>选择收货地址
						<el-button style="float: right;margin-right: 85px;" size="small" class="nextStep" type="primary" @click="goSettings">新增收货地址</el-button>
						</h4>
						<el-select v-if="addressList.length>0" v-model="value" placeholder="请选择">
						    <el-option
						      v-for="item in addressList"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						  <el-select v-if="addressList==[]"  placeholder="请选择"> -->
						      <!-- <el-option
						        v-for="item in addressList"
						        :key="item.value"
						        :label="item.label"
						        :value="item.value">
						      </el-option> -->
							<!--  <el-option  label="暂无地址"></el-option>
						    </el-select> -->
						<!-- <div @click="selectNewAddress(item.id)" class="address" v-for="(item,index) in addressList"
							:key="item.id">
							<div>
								<span class="shname">{{item.userName}}</span>
								<span class="shphone">{{item.phone}}</span>
								<span>{{item.cityInfo+item.address}}</span>
								<span v-if="isSelect&&ruleForm.addressId==item.id" style="color: red;" >   选中了该地址</span>
							</div>
						</div> -->
						<div style="width:30%;text-align: right;margin-top: 3.125rem;padding-left: 20.75rem;display: flex;">
							<el-button @click="goOrderThree" class="back" type="button">返回</el-button>
							<el-button @click="nextStepOrder" class="nextStep" type="button">确认提交</el-button>
						</div>

					</div>
				</div>
			</div>


		</div>
		<el-dialog title="收货地址" :visible.sync="dialogFormVisible" top="40vh">
			<el-form :model="addressForm" :rules="rules" ref="addressForm" label-width="110px" class="addressForm">
				<el-form-item label="医生姓名" prop="userName">
					<el-input v-model="addressForm.userName" placeholder="请输入收货医生姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="addressForm.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
			<!-- 	<el-form-item label="所在地址" prop="address">
					<el-input v-model="addressForm.address" placeholder="小区楼栋/乡村名称"></el-input>
				</el-form-item> -->
				<el-form-item label="详细地址" prop="detailAddress">
					<el-input v-model="addressForm.address" placeholder="请输入详细地址"></el-input>
				</el-form-item>
				<!-- <el-form-item label="工作单位">
					<el-input v-model="addressForm.workAddress" placeholder="请输入工作单位"></el-input>
				</el-form-item> -->

				<!-- el-form-item label="设置为默认地址">
					<el-switch v-model="addressForm.isDefaultAddress"></el-switch>
				</el-form-item> -->
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				
				one:'',
				value:'',
				isSelect:false,
				defaultAddress:'',
				checkList: [],
				dialogFormVisible: false,
				ruleForm: {
					oid: '',
					doctorId: '',
					addressId:''
				},
				addressList: [],
				radio: '',
				addressForm: {
					doctorId: '',
					regionId: 2008,
					userName: '',
					phone: '',
					address: ''
				},
				rules: {
					userName: [{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 5,
							message: '长度在 2 到 5 个字符',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
							message: '手机格式有误',
							trigger: 'blur'
						}
					],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}, ],
					// detailAddress: [{
					// 	required: true,
					// 	message: '请输入详细地址',
					// 	trigger: 'blur'
					// }, ]
				}
			}
		},
		methods: {
			//全选
			allChecked(){
				var isChecked=document.getElementsByClassName("abc0")[0].checked;
				console.log(document.getElementsByClassName("abc0")[0].checked);
				if(isChecked===true){
					document.getElementsByClassName("abc1")[0].checked=true;
					document.getElementsByClassName("abc2")[0].checked=true;
					document.getElementsByClassName("abc3")[0].checked=true;
				}else{
					document.getElementsByClassName("abc1")[0].checked=false;
					document.getElementsByClassName("abc2")[0].checked=false;
					document.getElementsByClassName("abc3")[0].checked=false;
				}
			},
			//三个全选中,全选也得选中
			checkedOne(){
				var abc1=document.getElementsByClassName("abc1")[0].checked;
				var abc2=document.getElementsByClassName("abc2")[0].checked;
				var abc3=document.getElementsByClassName("abc3")[0].checked;
				
				if(abc1==true&&abc2==true&&abc3==true){
					document.getElementsByClassName("abc0")[0].checked=true;
				}else{
					document.getElementsByClassName("abc0")[0].checked=false;
				}
			},
			//接受政策
			zhengCe(){
				this.$router.push("/hxmain/hxzhengce")
			},
			//去个人中心
			goSettings(){
				this.$router.push("/hxmain/hxsettings");
				this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderten");
			},
			goOrderThree(){
				this.$store.commit("setHxStepIndex",9);
				this.$router.push("/hxmain/hxorder/hxordernine");
			},
			//下一步
			nextStepOrder() {
				
				this.ruleForm.addressId=this.value;
				if(this.ruleForm.addressId==""){
					this.$alert("请选择收货地址","提示");
					return;
				}
				var abc1=document.getElementsByClassName("abc1")[0].checked;
				var abc2=document.getElementsByClassName("abc2")[0].checked;
				var abc3=document.getElementsByClassName("abc3")[0].checked;
				
				if(abc1==false||abc2==false||abc3==false){
					this.$alert("请勾选协议","提示");
					return;
				}
				
				if(this.one==""){
					this.$axios.get("/client/order/submitOrder", {
						params: {
							addrId: this.ruleForm.addressId,
							oid: this.ruleForm.oid
						}
					}).then(res => {
						if (res.data.code == 200) {
							this.$message({
								type:'success',
								message:"提交订单成功",
								duration:1000,
								offset:300
							});
							//this.$alert("操作订单成功","提示");
							
							this.$router.push("/hxmain/hxlatestorder");
						}
					}).catch(err => {
						this.$alert("请联系管理员", "提示");
					});
				}
				
			},
			//单击一个地址选择新地址
			selectNewAddress(addrId) {
				
				this.ruleForm.addressId=addrId;
				this.isSelect=true;
				// this.$axios.get("client/order/addOrderAddress", {
				// 	params: {
				// 		addrId: addrId,
				// 		oid: this.ruleForm.oid
				// 	}
				// }).then(res => {
				// 	if (res.data.code == 200) {
				// 		this.$message.success("选择地址成功", "提示");
						
				// 		this.$router.push("/ysmmain/ysmorder/ysmlatestorder");
				// 	}
				// }).catch(err => {
				// 	MessageBox.alert("请联系管理员", "提示");
				// });
			},
			//添加新的收货地址
			toNewAddress() {
				this.dialogFormVisible = true;
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.addressForm.doctorId=this.ruleForm.doctorId;
						
						this.$axios.post("client/order/addOrder",JSON.stringify(this.addressForm),{
							headers:{
								"Content-type":"application/json"
							}
						}).then(res=>{
							if(res.data.code==200){
								this.$alert('添加地址成功', '提示', {
									confirmButtonText: '确定',
									callback: action => {
										this.$router.push("/hxmain/hxorder/hxorderten");
									}
								});
							}
							
						}).catch(err=>{
							this.$alert('添加地址失败', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									// this.$router.push("/ysmmain/ysmorder/ysmorderten");
									this.$router.push("/hxmain/hxorder/hxorderten");
								}
							});
						})
						this.dialogFormVisible = false;
						//this.$router.push("/hxmain/hxorder/hxordereleven");
					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		mounted: function() {
			this.$store.commit('setHxStepIndex', 10);
			//设置默认地址
			sessionStorage.setItem("location", "/hxmain/hxorder/hxorderten")

			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation", "/hxmain/hxorder/hxordernine");
		},
		created: function() {
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die")
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
				document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
	/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
	 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
	document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
	document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3");	 */	
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
			this.ruleForm.doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			
			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("client/doctor/queryDoctorAddress", {
					params: {
						doctorId: this.ruleForm.doctorId
					}
				}).then(res => {
					
					//常用地址
					let alist=res.data.data.addressList;
					for(let i=0;i<alist.length;i++){
						let  addObj={"label":alist[i].userName+" "+alist[i].phone+" "+alist[i].cityInfo+" "+alist[i].address,"value":alist[i].id};
						this.addressList.push(addObj);
					}
					/* console.log(res.data.data.isdefault)
					console.log(res.data.data.isdefault.isDefault!=0) */
				if(res.data.data.defaultAddress.isdefault!=0){
					this.value=res.data.data.defaultAddress.id;
				}else{
					this.value="";
				}
				console.log(this.value);
					//默认地址
					
					this.radio = res.data.data.defaultId;
				}).catch(err => {
					
					this.$message.error("请联系管理员");
				});
			}
		},
	}
</script>

<style scoped="scoped">
	.hx-order-ten {
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	a:hover{
		text-decoration: underline;
	}

	.left {
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		width: 560px;
	}

	/* 按钮 */
	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}

	.hzinfo span {
		font-size: 0.9375rem;
		display: inline-block;
		margin-left: 1.25rem;
	}

	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}


	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}

	.shname {
		display: inline-block;
		width: 8.5rem;
	}

	.shphone {
		display: inline-block;
		width: 8.5rem;
	}
	
</style>
